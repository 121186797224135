import App from './components/app';
import { JSONUncrush } from 'jsoncrush';
import { useEffect } from 'preact/hooks';

/** 
 * 11=2,
 */

let answers = {}
let step = 0;

if (typeof window !== "undefined" && typeof URLSearchParams !== 'undefined') {
    const url = new URLSearchParams(window.location.search);
    const obj = url.get('q');
    if (obj) {
        let val = atob(decodeURIComponent(obj));
        val = decodeURIComponent(val);
        val = JSONUncrush(val);
        answers = JSON.parse(val);
        step = Object.keys(answers).length;

    }
}

const initialState = {
    step: step,
    answers: answers
};

const Wrapper = () => {

    useEffect(() => {


        // Append facebook sdk
        if (document.body) {

            window.fbAsyncInit = function() {
                FB.init({
                  appId            : '1551414871691067',
                  autoLogAppEvents : true,
                  xfbml            : true,
                  version          : 'v5.0'
                });
            };

            const script = document.createElement("script");
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;
        
            document.body.appendChild(script);
        }

    }, []);

    return (<>
        <link href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Indie+Flower&family=Open+Sans+Condensed:wght@700&family=Open+Sans:wght@400;700&display=swap" rel="stylesheet" />

        <App initialState={ initialState } />
    </>);
}

export default Wrapper;

