export default [
    {
        title: "Clear gutters and drains", 
        questionText: 'When was the last time you cleared the gutters and drains?',
        period: 12, 
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget it's recommended to do this every 12 months",
        diyInstructions: "You'll need a long ladder and a bucket and some gloves (if you don't already have these things), which will cost around £155. Unless you have a head for heights and someone to hold the ladder firm you might prefer to consider paying a professional.",
        proInstructions: "Hiring a professional to do this job will cost around £50."
    },
    {
        title: "Boiler service",
        questionText: "When was the last time you had your boiler serviced?",
        period: 12, 
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months.",
        diyInstructions: "Unless you're a qualified gas engineer this is one job you'll need to call a professional for! <a href=\"https://www.admiral.com/magazine/guides/home/is-your-boiler-winter-ready\">There are a few simple things you can do at home to help prevent your boiler packing in when you most need it.</a>",
        proInstructions: "Hiring a professional for a boiler service will cost around £70."
    },
    {
        title: "Electrical safety check",
        questionText: "When was the last time you had an electrical safety check?",
        period: 120,
        overdueText: "It's recommended that this is completed every 10 years for owner occupied homes, every five years for a rental property.",
        upToDateText: "Don't forget, it's recommended that this is completed every 10 years.",
        diyInstructions: "Unless you're a qualified electrician this is one job you'll need to call a professional for!",
        proInstructions: "Hiring a professional to do this job will cost around £150-£200 dependent on property size."
    },
    {
        title: "Check and replace sealant round baths, showers, sinks",
        questionText: "When was the last time you checked and replaced sealant around baths, showers and sinks?",
        period: 24,
        overdueText: "It's recommended that this is completed every two years.",
        upToDateText: "Don't forget, it's recommended that this is checked and replaced every two years.",
        diyInstructions: "To do any basic repairs you'll need a tube of sealant, a sealant remover tool and a smoothing tool, and possibly a stanley knife which will cost around £30.",
        proInstructions: "Replacing the silicone is an easy job to DIY, but if you get really stuck you'll need to call a local handyperson, which usually costs £20-£30 per hour."
    },
    {
        title: "Bleed the radiators",
        questionText: "When was the last time you bled your radiators?",
        period: 12,
        overdueText: "It's recommended that this is completed every 12 months, or if you notice cold spots on your radiators.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months, or if you feel a cold patch on your radiator.",
        diyInstructions: "All you need is a radiator key, which wil cost around £2 if you need to buy a new one and an old rag. If you still experience cold patches on your radiators you'll need a professional to help flush the system. <a href=\"https://www.admiral.com/magazine/guides/home/how-to-bleed-a-radiator\">Here’s a handy guide to help you bleed a radiator yourself.</a>",
        proInstructions: "Hiring a professional to flush the system and eliminate radiator cold spots will cost around £35 per hour, with the length of time dependent on the size of your home and number of radiators."
    },
    {
        title: "Clean the bathroom and kitchen extractors",
        questionText: "When was the last time you cleaned your bathroom and kitchen extractors?",
        period: 1,
        overdueText: "It's recommended that you give your extractors a clean once a month to keep them functioning well.",
        upToDateText: "Don't forget, it's recommended that this is completed every month.",
        diyInstructions: "Easily done, with a bucket and some hot soapy water and a cloth - things you should already have at home!",
        proInstructions: "You really don't need a pro to do this job, but a local cleaner or handyperson could help if you're really stuck! A handyperson usually costs £20-£30 per hour."
    },
    {
        title: "Test and replace fire/carbon monoxide alarms",
        questionText: "When was the last time you tested and replaced your fire/carbon monoxide alarms?",
        period: 12,
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months to keep everything working well. Test your alarms when the clocks are changed and change the battery once a year (unless it's a 10-year alarm).",
        diyInstructions: "Replacing both alarms will cost around £42 (for battery powered devices).",
        proInstructions: "Hiring a professional to replace a mains fitted device will cost around £80."
    },
    {
        title: "Clean and maintain chimneys/woodburners",
        questionText: "When was the last time you cleaned your chimneys/woodburners?",
        period: 12,
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months.",
        diyInstructions: "This is a messy and time consuming job for a non professional. It's recommended that this is done by a professional.",
        proInstructions: "Hiring a HETAS registered professional to do this job will cost around £100."
    },
    {
        title: "Prune large trees and shrubs",
        questionText: "When was the last time you pruned any large trees or shrubs?",
        period: 12,
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months",
        diyInstructions: "You'll need a long ladder, some telescopic and/or anvil loppers and possibly a saw, which could cost around £195. Trees can wreak havoc if not appropriately looked after, <a href=\"https://www.admiral.com/magazine/guides/home/insuring-against-damage-caused-by-trees\">here’s what to do if a tree falls on your home.</a>",
        proInstructions: "A local tree surgeon is the person to call if it’s a large tree that needs to be pruned or felled. Prices will vary depending on tree size, type and location, how many people the job requires and whether you need them to remove the debris afterwards. One or two people teams usually cost around £400-£550 per day of work."
    },
    {
        title: "Check roof tiles and replace any broken or missing ones",
        questionText: "When was the last time you checked your roof tiles?",
        period: 60,
        overdueText: "It's recommended that this is completed once every five years, unless you have cause for concern.",
        upToDateText: "Don't forget, it's recommended that you check your roof at least once every five years",
        diyInstructions: "Checking them is free - repair or replacement will depend on the problem. Not including the cost of any new tiles, at the minimum you'd need a roof ladder, wire brush and some roof repair mastic, costing around £101.",
        proInstructions: "You'd need to hire a roofer to inspect the problem and costs can vary based on the roof type and issue - aim to get a couple of quotes and pick a tradesperson based on a recommendation where possible. To replace half a dozen tiles will usually cost in the region of £50-£100."
    },
    {
        title: "Check downpipes and repair if necessary",
        questionText: "When was the last time you checked the downpipes?",
        period: 12,
        overdueText: "It's recommended that this is completed every 12 months.",
        upToDateText: "Don't forget, it's recommended that this is completed every 12 months.",
        diyInstructions: "If your downpipes need cleaning out tools you'll need could include a long ladder, pressure washer, a bucket, trowel, gutter cleaning kit and some gloves, costing an estimated £285 in total.",
        proInstructions: "Costs for repairing a downpipe will vary depending on what the pipes are made from, as a rough guide budget around £25 per metre for PVCu and £75 a metre for cast iron. Labour for the repairs can cost around £150 a day, but a simple repair could take as little as 30 minutes."
    },
    {
        title: "Check for leaks and repair as necessary",
        questionText: "When was the last time you checked for leaks?",
        period: 24,
        overdueText: "It's recommended that this is completed every two years as a minimum.",
        upToDateText: "Don't forget, it's recommended that this is completed every two years to avoid any bigger issues.",
        diyInstructions: "If you find a leak in a pipe at home, you might be able to repair it yourself, depending on the severity. A basic toolkit would need to include pipe putty and sealant, a screwdriver, slip joint pliers, files, nut drivers and a tape measure, costing a collective £45.",
        proInstructions: "Hiring a professional to fix a leak could cost around £35 an hour plus parts."
    },
];