
import styled from 'styled-components'
import BackgroundImage from '../assets/background.svg';
import HandsRepeat from '../assets/hands-repeat.svg';
import { animated } from 'react-spring';


export const Wrapper = styled.div`
	display:block;
	height:100%;
	min-height:100vh;
	background-image:url('${BackgroundImage}'), url('${HandsRepeat}');
	background-repeat:no-repeat, repeat-y;
	background-size:100%;
	background-color:#448CD4;
	background-position:top center, bottom, center;
	overflow:hidden;
	* {
	
		font-family: 'Open Sans', sans-serif;
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
	}
`;

export const Container = styled.div`
	padding-left:15px;
	padding-right:15px;
	margin:0 auto;
	height:100%;
	max-width:1285px;
	padding-top:40px;
	position:relative;

	@media (min-width: 768px) { 
		padding-top:20px;
	}
	
`;

export const Header = styled.header`
	text-align: right;
	height:150px;
	position:absolute;
	top:20px;
	right:20px;
	width:100%;
	align-self:flex-end;
	max-width:100%;
	img {
		padding: 10px;
		border-radius: 50px;
		background-color: #ffffff;
		margin-right:0px;
		width:120px;
	}

	@media (min-width: 768px) { 
		img {
			margin-right: 50px;
			width:auto;
		}
	}
`;


export const House = styled.div`
	margin:0 auto;
	max-width:761px;
	position:relative;
	margin-top:80px;
	margin-bottom:20px;
`;


// export const Roof = styled.svg`
// 	position:absolute;
// 	width:100%;
// 	transform:scale(1.05) translateY(-25%);

// 	@media (max-width: 375px) {
// 		transform:scale(1.1) translateY(-20%);
// 	}

// 	@media (min-width: 930px) { 
// 		transform:scale(1.2) translateY(-20%);
// 	}

// `;

export const Roof = styled(animated.svg)`
	position:absolute;
	width:100%;
	transform:scale(1.05) translateY(-25%);

	@media (max-width: 375px) {
		transform:scale(1.1) translateY(-20%);
	}

	@media (min-width: 930px) { 
		transform:scale(1.2) translateY(-20%);
	}
`;

export const Triangle = styled.svg`
	margin-bottom: -12px;
	@media (min-width: 768px) { 
		margin-bottom: -20px;
	}
	
`;


export const InnerContent = styled.div`
	width:100%;
	margin: 0 auto;
	background-color:#2350A0;
	padding:20px;
	position:relative;
	background: #2350A0;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	margin-bottom:20px;
`;

export const Page = styled.div`
	margin-top:-50px;
	@media (min-width: 768px) { 
		margin-top:-150px;
	}
`;

export const Heading = styled.img`
	display: block;
	max-width:100%;	
	z-index:10;
	position:relative;
`;

export const List = styled.div`
	align-items: center;

	justify-content: center;
	margin-top: 40px;
	display:none;
	width:100%;
	@media (min-width: 768px) {
		display:flex;
	}
`;

export const ListItems = styled.ul`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin:0;
	padding:0;
	width:100%;
	margin-top: 40px;
	@media (min-width: 768px) {
		margin-top:0;
	}
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 2px;
	font-size: 20px;
	background-color: #ffffff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	overflow: hidden;
	opacity: ${props => props.active ? 1 : 0.2};
	text-align: center;
	line-height: 1;
	flex-shrink: 0;
	
	color: #2350A0;
	
	font-family: 'Gloria Hallelujah', cursive;

`;

export const Icons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
	flex-wrap:wrap;
`;

export const Icon = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-bottom:20px;
	transition:all .2s;
	
	/* opacity: 0.3; */
	
	&:hover {
		transform:scale(1.1);
	}	

	a {
		background-color: rgba(0,0,0,0);
		width:99px;
		border-radius:50%;	
		height:99px;
		display:block;
		transition:all .4s;
	}
	&:active {
		a {
			/* background-color:rgba(255, 223, 67, 1); */
		}	
	}
`;

export const Text = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: #ffffff;
	text-align: center;
	padding-top: 5px;
`;

export const Button = styled.button`
	background-color: transparent;
	border-radius: 50px;
	border: 2px solid #ffffff;
	padding: 15px;
	font-size: 14px;
	font-weight: bold;
	width: 180px;
	display: block;
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: 40px;
`;

export const Question = styled.div`
	width: 80%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	padding-top: 20px;
	position:relative;
	overflow:hidden;
	p {
		margin:0;
	}
	@media (min-width: 768px) {
		font-size: 30px;
	}
`;

export const YellowText = styled.span`
	color: #FFDF43;
`;

export const TaskHeading = styled.div`
	font-family: 'Open Sans', sans-serif;
	font-size: 22px;
	color: #FFFFFF;
	letter-spacing: -0.47px;
	font-weight:bold;
`;

export const TaskNote = styled.img`
	font-family: 'Open Sans', sans-serif;
	font-size: 24px;
	color: #E94C40;
	max-width:100%;
	
	
`;
export const Instructions = styled.div`
	
`;

export const Instruction = styled.div`
	font-size: 14px;
	color: #FFFFFF;
	margin-bottom:5px;
	font-family: 'Open Sans', sans-serif;
	strong {
		font-family: 'Open Sans', sans-serif;
	}
`;


export const ResultsPageWrapper = styled.div`
	max-width:555px;
	width:100%;
	margin: 0 auto;

`;

export const ResultItemWrapper = styled.div`
	border-bottom: 1px solid rgba(255,255,255,0.22);
	padding-bottom:30px;
	padding-top:30px;
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	flex-wrap:wrap;
	&:last-child {
		border-bottom:none;
	}
	
	@media (min-width: 768px) { 
		flex-wrap:nowrap;
	}

`;

export const ResultImage = styled.img`
	flex-shrink:0;
	max-width:100%;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:20px;
	@media (min-width: 768px) { 
		margin-bottom:0;
		margin:0;
		margin-right:20px;
	}
`;

export const ShareButton = styled.button`
	margin: 0 auto;
	font-weight:900;
	font-size:14px;
	margin:0 auto;
	color:#2350A0;
	background: #FFDF43;
	border-radius: 36px;
	padding-top:20px;
	padding-bottom:20px;
	padding-left:30px;
	padding-right:30px;
	text-transform:uppercase;
	margin-top:40px;
	margin-bottom:20px;
	
	font-family: 'Open Sans', sans-serif;

	border:none;
	cursor:pointer;
	display:block;
	min-width:200px;
	
`;
export const ResultText = styled.p`
	text-align:center;
`;

export const OutlineButton = styled.button`

	background:transparent;
	margin: 0 auto;
	border: 2px solid #FFFFFF;
	border-radius: 36px;
	display:block;
	padding-top:20px;
	padding-bottom:20px;
	padding-left:30px;
	padding-right:30px;	
	font-weight:900;
	color:#FFFFFF;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	cursor:pointer;
	min-width:200px;
	margin-bottom:40px;
`;


export const HeadingWrapper = styled.div`
	display:flex;
	flex-wrap:wrap;
	width: 100%;
	margin: 0 auto;
	margin-top:-50px;
	justify-content:center;
	@media (min-width: 768px) {
		width:60%;
	}
`;


export const Link = styled.a`
	border:none;
	text-decoration:none;
`;